<template>
    <div class="wrapper">

        <section class="encabezado -mis-mentorias">
            <h1 v-if="data">Valorar mentoría</h1>
        </section>

        <div class="contenedor-formulario" v-if="!complete">
            <ValidationObserver ref="form" v-slot="{failed,valid}">
                <form @submit.prevent="validate" class="formulario" :valid="valid" :failed="failed">

                    <div class="pregunta">

                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <div class="contenedor-radios -dark">

                                <h4>1. ¿Cuántas reuniones habéis mantenido?*</h4>


                                <div class="radio" :key="key" v-for="(el,key) in 5">
                                    <input type="radio" name="reunions" v-model="data.reunions" :id="'reunions-'+key"
                                        :value="key+1" :class="classes">
                                    <label :for="'reunions-'+key">{{el}}</label>
                                </div>

                                <div class="radio">
                                    <input type="radio" name="reunions" v-model="data.reunions" :id="'reunions-6'"
                                        :value="6" :class="classes">
                                    <label :for="'reunions-6'">Más de 5</label>
                                </div>

                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>

                            </div>
                        </ValidationProvider>

                    </div>

                    <div class="pregunta" v-if="data.reunions == 6">

                        <div class="contenedor-input -dark">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="morereunions">¿Cuántas?*</label>
                                <input type="text" :class="classes" v-model="data.morereunions" name="morereunions"
                                    id="morereunions">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>


                    <div class="pregunta">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <div class="contenedor-radios -dark">

                                <h4>2. ¿Crees que el número de 3 sesiones obligatorias es suficiente?*</h4>

                                <div class="radio">
                                    <input type="radio" name="enough" v-model="data.enough" id="enough-si" :value="1"
                                        :class="classes">
                                    <label for="enough-si">Sí</label>
                                </div>

                                <div class="radio">
                                    <input type="radio" name="enough" v-model="data.enough" id="enough-no" :value="0"
                                        :class="classes">
                                    <label for="enough-no">No</label>
                                </div>

                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>

                            </div>
                        </ValidationProvider>

                    </div>

                    <div class="pregunta">

                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">

                            <div class="contenedor-radios -dark">

                                <h4>3. Modalidad de las reuniones*</h4>

                                <div class="radio" :key="key" v-for="(el,key) in domains.preferences">
                                    <input type="radio" name="modality" v-model="data.modality" :id="'modality-'+key"
                                        :value="key" :class="classes">
                                    <label :for="'modality-'+key">{{el}}</label>
                                </div>

                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>

                            </div>

                        </ValidationProvider>

                    </div>

                    <div class="pregunta">

                        <div class="contenedor-input -dark">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="themes">4. Temas tratados*</label>
                                <textarea :class="classes" v-model="data.themes" name="themes" id="themes"></textarea>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                    </div>

                    <div class="pregunta">

                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <div class="contenedor-radios -dark">

                                <h4 v-if="usertype == 'mentor'">5. Evalúa el nivel de compromiso / interés de tu mentee*
                                </h4>
                                <h4 v-else>5. Evalúa el nivel de compromiso / interés de tu mentor/a*</h4>

                                <p class="help"><span>Del 1 (Nada comprometido/a) al 10 (Muy comprometido/a)</span></p>

                                <div class="radio" :key="key" v-for="(el,key) in 10">
                                    <input type="radio" name="interest" v-model="data.interest" :id="'interest-'+key"
                                        :value="key+1" :class="classes">
                                    <label :for="'interest-'+key">{{el}}</label>
                                </div>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>

                            </div>
                        </ValidationProvider>

                    </div>

                    <div class="pregunta" v-if="data.interest < 5 && data.interest != null">

                        <div class="contenedor-input -dark" >
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="interest_info">Indícanos el motivo*</label>
                                <textarea :class="classes" v-model="data.interest_info" name="interest_info"
                                    id="interest_info"></textarea>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                    </div>


                    <div class="pregunta">

                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <div class="contenedor-radios -dark">

                                <h4>6. Indica el grado de satisfacción con respecto a este Programa.*</h4>

                                <p class="help"><span>Del 1 (Nada satisfecho/a) al 10 (Muy satisfecho/a)</span></p>

                                <div class="radio" :key="key" v-for="(el,key) in 10">
                                    <input type="radio" name="program" v-model="data.program" :id="'program-'+key"
                                        :value="key+1" :class="classes">
                                    <label :for="'program-'+key">{{el}}</label>
                                </div>

                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>

                            </div>
                        </ValidationProvider>

                    </div>

                    <div class="pregunta" v-if="data.program < 5 && data.program != null">

                        <div class="contenedor-input -dark">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="program_info">Indícanos el motivo*</label>
                                <textarea :class="classes" v-model="data.program_info" name="program_info"
                                    id="program_info"></textarea>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                    </div>

                    <div class="pregunta">

                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <div class="contenedor-radios -dark">

                                <h4>7. Valora la aplicación informática (Accesibilidad, usabilidad y funcionamiento
                                    general)*
                                </h4>
                                <p class="help"><span>Del 1 (Nada adecuadas) al 10 (Muy adecuadas)</span></p>
                                <div class="radio" :key="key" v-for="(el,key) in 10">
                                    <input type="radio" name="app" v-model="data.app" :id="'app-'+key" :value="key+1"
                                        :class="classes">
                                    <label :for="'app-'+key">{{el}}</label>
                                </div>

                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>

                            </div>
                        </ValidationProvider>

                    </div>


                    <div class="pregunta" v-if="data.app < 5 && data.app != null">

                        <div class="contenedor-input -dark">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="app_info">¿Qué aspectos de la plataforma crees que se pueden
                                    mejorar?*</label>
                                <textarea :class="classes" v-model="data.app_info" name="app_info"
                                    id="app_info"></textarea>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                    </div>

                    <div class="pregunta">

                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">

                            <div class="contenedor-radios -dark">

                                <h4 v-if="usertype == 'mentor'">8. ¿Recomendarías a posibles mentores participar en el
                                    programa?*
                                </h4>
                                <h4 v-else>8. ¿Recomendarías a otros/as compañeros/as la participación en el Programa?*
                                </h4>

                                <div class="radio">
                                    <input type="radio" name="recommend" v-model="data.recommend"
                                        id="recommend-si" :value="1" :class="classes">
                                    <label for="recommend-si">Sí</label>
                                </div>

                                <div class="radio">
                                    <input type="radio" name="recommend" v-model="data.recommend"
                                        id="recommend-no" :value="0" :class="classes">
                                    <label for="recommend-no">No</label>
                                </div>

                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>

                            </div>

                        </ValidationProvider>

                    </div>

                    <div class="pregunta">

                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <div class="contenedor-radios -dark">
                                <h4>9. ¿Cuál de estas otras actividades organizadas por Alumni te interesan?*</h4>
                                <div class="checkbox" :key="key" v-for="(el,key) in domains.activities">
                                    <input name="activities" type="checkbox" v-model="data.activities" :value="el"
                                        :id="'activiti-'+key" :class="classes">
                                    <label :for="'activiti-'+key">{{el}}</label>
                                </div>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>

                    </div>

                    <div class="pregunta" v-if="data.activities.includes('Otra')">
                        <div class="contenedor-input -dark">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="moreactivities">¿Cual?*</label>
                                <input type="text" :class="classes" v-model="data.moreactivities" name="moreactivities"
                                    id="moreactivities">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                    </div>

                    <div class="pregunta">
                        <div class="contenedor-input -dark">
                            <label for="comments">10. Comentarios y sugerencias</label>
                            <textarea v-model="data.comments" name="comments" id="comments" :class="classes"></textarea>
                        </div>
                    </div>

                    <div class="contenedor-boton">
                    </div>

                </form>
            </ValidationObserver>

            <p class="help">La cumplimentación de la valoración supondrá la finalización de la mentoría.</p>

            <a @click="sendData" class="btn -dark-orange" :loading="loading">Enviar</a>
        </div>

        <div class="contenedor-gracias" v-if="complete">

            <h2>Muchas gracias por tu valoración</h2>

            <router-link to="/" class="btn -dark-orange">Volver</router-link>

        </div>

    </div>
</template>


<script>

    import { mapGetters, mapActions } from 'vuex';


    export default {
        name: 'valoracion-mentoria',
        mounted() {
            this.data.id = this.$route.params.id;

        },
        data: () => ({
            data: {
                "id": '',
                "type": "",
                "reunions": null,
                "morereunions": '',
                "enough": null,
                "modality": null,
                "themes": "",
                "interest": null,
                "interest_info": null,
                "program": null,
                "program_info": "",
                "app": null,
                "app_info": "",
                "recommend": '',
                "activities": [],
                "comments": ""
            },
            loading: false,
            complete: false

        }),
        methods: {
            ...mapActions(['sendValoracion']),
            sendData() {

                this.$refs.form.validate().then(success => {
                    if (success) {

                        let data = JSON.parse(JSON.stringify(this.data));

                        data.activities.push(data.moreactivities);



                        if (data.reunions == 6) {
                            data.reunions = data.morereunions;
                        }


                        this.loading = true;
                        this.sendValoracion(data).then(r => {
                            this.loading = false;
                            if (r) {
                                this.complete = true;
                            }
                        }).catch(() => {
                            this.loading = false;
                        })

                    }
                });
            }
        },
        computed: {
            ...mapGetters({
                domains: 'getDomains',
                usertype: 'getUserType',
            })
        },
    }
</script>


<style lang="scss" scoped>
    .contenedor-formulario {
        width: 100%;
        margin-top: 50px;
    }

    .contenedor-radios {
        justify-content: center;

        h4 {
            width: 100%;
            text-align: left;
            display: inline-block;
            margin-bottom: 30px;
        }

        .radio{
            margin-right: 30px;
        }

        .help{
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            display: inline-block;
            color:#4a4a4a;
            margin-bottom: 20px;
            margin-top: 15px;
        }
    }

    .contenedor-radios .checkbox {
        width: 100%;
        margin-bottom: 10px;
        text-align: left;
    }

    .help {
        width: 100%;
        display: inline-block;
        text-align: left;
        font-weight: bold;
        margin-bottom: 30px;
        font-size: 18px;
    }

    .pregunta{
        width: 100%;
        margin-bottom: 30px;
    }

</style>